import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';

// images
import { ReactComponent as IMG_LOADING } from '../../loading.svg';

// main
const LoadingModal = ({ loading = false, sx }) => (
  // main
  <Modal open={loading}>
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10rem',
        ...sx,
      }}
    >
      <SvgIcon component={IMG_LOADING} inheritViewBox fontSize="inherit" />
    </Box>
  </Modal>
);
export default LoadingModal;
